<template>
     <SpaceBuilder/>
</template>

<script>


import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
export default {
    name: "CompanySettingView.vue",
    components: {SpaceBuilder, },
    setup(){
    }
}
</script>

<style lang="scss">

.company-setting{

    .company_fullname{
        $width: 150px;
        @include assignValue(width max-width min-width, $width);
    }

    .company_name{
        $width: 100px;
        @include assignValue(width max-width min-width, $width);
    }

    .company_ico{
        $width: 70px;
        @include assignValue(width max-width min-width, $width);
    }

    .company_dic{
        $width: 75px;
        @include assignValue(width max-width min-width, $width);
    }

    .company_address{
        $width: 150px;
        @include assignValue(width max-width min-width, $width);
    }

    .company_street_name{
        $width: 150px;
        @include assignValue(width max-width min-width, $width);
    }

    .company_building_number{
        $width: 45px;
        @include assignValue(width max-width min-width, $width);
    }

    .company_city_name{
        $width: 100px;
        @include assignValue(width max-width min-width, $width);
    }

    .company_postal_zone{
        $width: 50px;
        @include assignValue(width max-width min-width, $width);
    }

    .company_country_id{
        $width: 100px;
        @include assignValue(width max-width min-width, $width);
    }

    .company_tel_contact,
    .company_mail_contact,
    .company_accountant_contact{
        $width: 120px;
        @include assignValue(width max-width min-width, $width);
    }

    .company_default_account_id{
        $width: 90px;
        @include assignValue(width max-width min-width, $width);
    }

    .company_google_folder_id{
        $width: 230px;
        @include assignValue(width max-width min-width, $width);
    }

    .company_invoice_template_id{
        $width: 300px;
        @include assignValue(width max-width min-width, $width);
    }

    .company_vat_operator_type_id{
        $width: 120px;
        @include assignValue(width max-width min-width, $width);
    }

    .company_vat_operator_member_id{
        $width: 120px;
        @include assignValue(width max-width min-width, $width);
    }


}

</style>
